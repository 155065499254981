'use client'

import { useEffect } from 'react'
import Script from 'next/script'
import { storyblokEditable } from '@storyblok/react/rsc'
import Box from '@audioeye-marketing/ui/src/components/Box'
import trackEvent from '@audioeye-marketing/ui/src/lib/track-event'

const ScheduleMeeting = ({ blok }) => {
  let mappedData = {}

  if (typeof window !== 'undefined') {
    const hsFormData = window.sessionStorage.getItem('hs-form-data')
    if (hsFormData) {
      const parsedData = JSON.parse(hsFormData)

      mappedData = parsedData.reduce((acc, item) => {
        acc[item.name] = item.value
        return acc
      }, {})
    }
  }

  const meetingUrl = `https://meetings.hubspot.com/${blok.meeting_id}?embed=true&firstname=${
    mappedData.firstname || ''
  }&lastname=${mappedData.lastname || ''}&email=${mappedData.email || ''}`

  useEffect(() => {
    const handleMessage = (e) => {
      if (e?.data?.meetingBookSucceeded !== undefined) {
        if (e?.data?.meetingBookSucceeded === true) {
          trackEvent(`Hubspot Meeting Scheduled`, {
            'Hubspot URL': meetingUrl,
          })
        } else {
          trackEvent(`API Exception`, {
            Label: `Hubspot Meeting General Error`,
            'Full Exception': e?.data?.meetingsPayload,
          })
        }
      }
    }

    window.addEventListener('message', handleMessage)

    return () => {
      window.removeEventListener('message', handleMessage)
    }
  }, [meetingUrl])

  return (
    <Box {...storyblokEditable(blok)}>
      <div className="meetings-iframe-container" data-src={meetingUrl} />
      <Script type="text/javascript" src="https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js" />
    </Box>
  )
}

export default ScheduleMeeting
