'use client'

import { useState } from 'react'
import { StoryblokServerComponent, storyblokEditable } from '@storyblok/react/rsc'
import AccordionGroup from '@audioeye-marketing/ui/src/components/AccordionGroup'
import Grid from '@audioeye-marketing/ui/src/components/Grid'

const AccordionTabs = ({ blok, backgroundColor }) => {
  const [currentTab, setCurrentTab] = useState(0)
  const currentImage = blok?.items[currentTab]?.image[0]

  return (
    <Grid direction="row" container spacing={{ xs: 5, md: 5 }}>
      <Grid xs={12} md={6}>
        <AccordionGroup {...storyblokEditable(blok)} size="lg">
          {blok?.items?.map((item, index) => (
            <StoryblokServerComponent
              key={item?._uid}
              blok={item}
              backgroundColor={backgroundColor}
              index={index}
              setCurrentTab={setCurrentTab}
              expanded={index === currentTab}
            />
          ))}
        </AccordionGroup>
      </Grid>
      <Grid xs={12} md={6} sx={{ display: { xs: 'none', md: 'flex' } }}>
        {Boolean(currentImage) && <StoryblokServerComponent blok={currentImage} />}
      </Grid>
    </Grid>
  )
}

export default AccordionTabs
