'use client'

import { useState, useRef } from 'react'
import { StoryblokServerComponent } from '@storyblok/react/rsc'
import Grid from '@audioeye-marketing/ui/src/components/Grid'
import Box from '@audioeye-marketing/ui/src/components/Box'
import Button from '@audioeye-marketing/ui/src/components/Button'
import colors from '@audioeye-marketing/ui/src/theme/colors'
import ChevronDown from '@audioeye/ui-icons/Line/Arrows/ChevronDown'

const MegaMenu = ({ blok, rels, navItemNumber = 0 }) => {
  const [menuOpen, setMenuOpen] = useState(false)
  const menuRef = useRef(null)

  return (
    <Box sx={{ position: 'relative', display: 'inline-block' }}>
      {/* Button to toggle the menu */}
      <Button
        onClick={() => setMenuOpen((prev) => !prev)}
        endDecorator={<ChevronDown />}
        aria-expanded={menuOpen}
        aria-controls="megamenu"
      >
        {blok?.text}
      </Button>

      {/* Dropdown menu */}
      {Boolean(menuOpen) && (
        <Box
          ref={menuRef}
          id="megamenu"
          role="menu"
          sx={{
            position: 'absolute',
            top: '100%',
            left: navItemNumber <= 2 ? '0' : 'auto',
            right: navItemNumber > 2 ? '0' : 'auto',
            zIndex: 1000,
            borderRadius: '0 0 8px 8px',
            padding: 0,
            maxWidth: '1160px',
            backgroundColor: '#fff',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            border: '1px solid var(--joy-palette-divider)',
            marginTop: '8px', // Small spacing below the button
          }}
        >
          <Grid container sx={{ borderTop: '1px solid var(--joy-palette-divider)' }}>
            <Grid item xs={3}>
              <Box
                pt={2}
                pb={2}
                pl={4}
                pr={4}
                sx={{
                  borderRight: '1px solid var(--joy-palette-divider)',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  height: '100%',
                }}
              >
                {blok?.cta?.map((currentCta) => (
                  <StoryblokServerComponent blok={currentCta} key={currentCta?._uid} rels={rels} />
                ))}
              </Box>
            </Grid>
            <Grid item xs={9}>
              <Box
                pt={2}
                pb={2}
                pl={5.5}
                pr={5.5}
                sx={{
                  backgroundColor: colors?.neutral?.[100] || '#f9f9f9',
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                {blok?.featured_item?.map((featuredItem) => (
                  <StoryblokServerComponent blok={featuredItem} key={featuredItem?._uid} horizontalForm />
                ))}
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}
    </Box>
  )
}

export default MegaMenu
