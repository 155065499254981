'use client'

import JoyLink from '@mui/joy/Link'
import Box from '../Box'
import trackEvent from '../../lib/track-event'
import getTextFromChildren from '../../lib/get-text-from-children'

const Link = ({ children, hoverUnderline, ...rest }) => {
  return (
    <JoyLink
      {...rest}
      onClick={() => {
        Boolean(rest.onClick) && rest.onClick()

        trackEvent('Link Clicked', {
          'Link Text': getTextFromChildren(children),
          Destination: rest.href,
          Target: rest.target,
        })
      }}
      rel={
        rest.target === '_blank' && !rest.href.startsWith('/') && !rest.href.includes('audioeye.com')
          ? 'noopener noreferrer'
          : undefined
      }
      underline={hoverUnderline ? 'hover' : 'always'}
      sx={{
        ...rest.sx,
        ...(!hoverUnderline && {
          '&:hover': {
            textDecoration: 'none',
          },
        }),
        display: 'inline',
      }}
    >
      {children}
      {rest.target === '_blank' && (
        <Box
          component="span"
          sx={{
            position: 'absolute',
            width: '1px',
            height: '1px',
            padding: '0',
            margin: '-1px',
            overflow: 'hidden',
            clip: 'rect(0, 0, 0, 0)',
            border: '0',
          }}
        >
          (opens in a new tab)
        </Box>
      )}
    </JoyLink>
  )
}

export default Link
