'use client'

import { useState, cloneElement } from 'react'
import JoyDrawer from '@mui/joy/Drawer'
import ModalClose from '@mui/joy/ModalClose'

const Drawer = ({ children, trigger, ...rest }) => {
  const [open, setOpen] = useState(false)
  const clonedTrigger = cloneElement(trigger, {
    onClick: () => {
      setOpen(true)
    },
  })

  return (
    <>
      {clonedTrigger}
      <JoyDrawer {...rest} open={open} onClose={() => setOpen(false)}>
        <ModalClose />
        {children}
      </JoyDrawer>
    </>
  )
}

export default Drawer
