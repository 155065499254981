// * https://www.figma.com/design/wGzZD97Ae7IiohGeogvZFe/%F0%9F%8C%8E-Global%3A-Primitives?node-id=56-571&node-type=frame&t=8VfLwo5lv3SSEew8-0
const typography = {
  h1: {
    fontSize: {
      xs: '2.5rem',
      md: '3.5rem',
    },
    lineHeight: {
      xs: '2.625rem',
      md: '3.625rem',
    },
    letterSpacing: '-1px',
    fontWeight: '600',
    fontFamily: 'var(--font-schibsted-grotesk)',
  },
  h2: {
    fontSize: {
      xs: '2.25rem',
      md: '3rem',
    },
    lineHeight: {
      xs: '2.625rem',
      md: '3.625rem',
    },
    letterSpacing: '-0.5px',
    fontWeight: '600',
    fontFamily: 'var(--font-schibsted-grotesk)',
  },
  h3: {
    fontSize: {
      xs: '1.75rem',
      md: '2.25rem',
    },
    lineHeight: {
      xs: '2rem',
      md: '2.75rem',
    },
    letterSpacing: '0px',
    fontWeight: '700',
    fontFamily: 'var(--font-schibsted-grotesk)',
  },
  h4: {
    fontSize: {
      xs: '1.25rem',
      md: '1.5rem',
    },
    lineHeight: {
      xs: '1.75rem',
      md: '2rem',
    },
    letterSpacing: '0px',
    fontWeight: '700',
    fontFamily: 'var(--font-schibsted-grotesk)',
  },
  'title-lg': {
    fontSize: {
      xs: '1.125rem',
      md: '1.125rem',
    },
    lineHeight: {
      xs: '1.75rem',
      md: '1.75rem',
    },
    letterSpacing: '0px',
    fontWeight: '500',
    fontFamily: 'var(--font-schibsted-grotesk)',
  },
  'title-md': {
    fontSize: {
      xs: '1rem',
      md: '1rem',
    },
    lineHeight: {
      xs: '1.5rem',
      md: '1.5rem',
    },
    letterSpacing: '0px',
    fontWeight: '500',
    fontFamily: 'var(--font-schibsted-grotesk)',
  },
  'title-sm': {
    fontSize: {
      xs: '.875rem',
      md: '.875rem',
    },
    lineHeight: {
      xs: '1.25rem',
      md: '1.25rem',
    },
    letterSpacing: '0px',
    fontWeight: '600',
    fontFamily: 'var(--font-schibsted-grotesk)',
  },
  'body-lg': {
    fontSize: {
      xs: '1.125rem',
      md: '1.125rem',
    },
    lineHeight: {
      xs: '1.75rem',
      md: '1.75rem',
    },
    letterSpacing: '0px',
    fontWeight: '400',
    fontFamily: 'var(--font-schibsted-grotesk)',
  },
  'body-md': {
    fontSize: {
      xs: '1rem',
      md: '1rem',
    },
    lineHeight: {
      xs: '1.5rem',
      md: '1.5rem',
    },
    letterSpacing: '0px',
    fontWeight: '400',
    fontFamily: 'var(--font-schibsted-grotesk)',
  },
  'body-sm': {
    fontSize: {
      xs: '.875rem',
      md: '.875rem',
    },
    lineHeight: {
      xs: '1.25rem',
      md: '1.25rem',
    },
    letterSpacing: '0px',
    fontWeight: '400',
    fontFamily: 'var(--font-schibsted-grotesk)',
  },
  'body-xs': {
    fontSize: {
      xs: '.75rem',
      md: '.75rem',
    },
    lineHeight: {
      xs: '1.125rem',
      md: '1.125rem',
    },
    letterSpacing: '0px',
    fontWeight: '500',
    fontFamily: 'var(--font-schibsted-grotesk)',
  },
  eyebrow: {
    fontSize: {
      xs: '.75rem',
      md: '.875rem',
    },
    lineHeight: {
      xs: '1.25rem',
      md: '1.25rem',
    },
    fontWeight: '400',
    letterSpacing: '2px',
    fontFamily: 'var(--font-dm-mono)',
    textTransform: 'uppercase',
  },
  'code-md': {
    fontSize: {
      xs: '1rem',
      md: '1rem',
    },
    lineHeight: {
      xs: '1.5rem',
      md: '1.5rem',
    },
    fontWeight: '400',
    letterSpacing: '0px',
    fontFamily: 'var(--font-dm-mono)',
  },
  'code-sm': {
    fontSize: {
      xs: '.875rem',
      md: '.875rem',
    },
    lineHeight: {
      xs: '1.25rem',
      md: '1.25rem',
    },
    fontWeight: '400',
    letterSpacing: '0px',
    fontFamily: 'var(--font-dm-mono)',
  },
  'code-xs': {
    fontSize: {
      xs: '.75rem',
      md: '.75rem',
    },
    lineHeight: {
      xs: '1.125rem',
      md: '1.125rem',
    },
    fontWeight: '400',
    letterSpacing: '0px',
    fontFamily: 'var(--font-dm-mono)',
  },
  // * these are not a new token in Joy UI because we need the element to stay the same but just be different font size (aka h1 element with a different font size)
  'display-lg': {
    fontSize: {
      xs: '3rem',
      md: '4rem',
    },
    lineHeight: {
      xs: '3.125rem',
      md: '4.25rem',
    },
    fontWeight: '600',
    letterSpacing: '-1px',
    fontFamily: 'var(--font-schibsted-grotesk)',
  },
  'display-md': {
    fontSize: {
      xs: '1.75rem',
      md: '2.25rem',
    },
    lineHeight: {
      xs: '2rem',
      md: '2.75rem',
    },
    fontWeight: '500',
    letterSpacing: '-1px',
    fontFamily: 'var(--font-schibsted-grotesk)',
  },
  'display-sm': {
    fontSize: {
      xs: '1.75rem',
      md: '1.75rem',
    },
    lineHeight: {
      xs: '2rem',
      md: '2.25rem',
    },
    fontWeight: '500',
    letterSpacing: '-1px',
    fontFamily: 'var(--font-schibsted-grotesk)',
  },
}

export default typography
