'use client'

import Stack from '../Stack'
import { useFormContext as useUIFormContext } from '../Form'
import Fieldset from '../Fieldset'
import HubspotFormFieldController from './HubspotFormFieldController'

const HubspotFormFieldGroups = ({ fieldGroups }) => {
  const { formName } = useUIFormContext()

  return (
    <>
      {fieldGroups?.map((group, groupIndex) => {
        // If all fields in the group are hidden, then hide the entire group
        return group?.fields?.every((field) => field.hidden) ? (
          // eslint-disable-next-line react/no-array-index-key -- there is no other way since there's nothing unique for these
          <Fieldset key={`${formName} - hidden fieldset - ${groupIndex}`} sx={{ display: 'none' }}>
            {group?.fields.map((field) => {
              return <HubspotFormFieldController key={field.name} field={field} />
            })}
          </Fieldset>
        ) : (
          // eslint-disable-next-line react/no-array-index-key -- there is no other way since there's nothing unique for these
          <Fieldset key={`${formName} - fieldset - ${groupIndex}`}>
            {group?.fields?.map((field) => {
              return (
                <Stack gap={2} key={`${formName} - ${field.name}`} sx={{ width: '100%', flex: 1 }}>
                  <HubspotFormFieldController field={field} />
                  {field?.dependentFields?.map((dependentField) => {
                    return (
                      <HubspotFormFieldController
                        key={`${formName} - ${dependentField.dependentField.name}`}
                        field={field}
                        dependentField={dependentField}
                      />
                    )
                  })}
                </Stack>
              )
            })}
          </Fieldset>
        )
      })}
    </>
  )
}

export default HubspotFormFieldGroups
